import React from 'react';
import { Link } from 'gatsby';
import { Container } from 'components/common';
import { Wrapper, Flex, Links, Details } from './styles';
import social from './social.json';

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <h2>Valentin Despa</h2>
        <span>
          {' '}
          © {new Date().getFullYear()} All rights are reserved | <Link to="/imprint/">Imprint</Link> | Version
          9dfb3d40 <br />
          <small>
            Based on a template created by{' '}
            <a href="https://smakosh.com/?ref=portfolio-dev" rel="noopener noreferrer" target="_blank">
              Smakosh.
            </a>
          </small>
        </span>
      </Details>
      <Links>
        {social.map(({ id, name, link, icon }) => (
          <a key={id} href={link} target="_blank" rel="noopener noreferrer" aria-label={`follow me on ${name}`}>
            <img width="24" src={icon} alt={name} />
          </a>
        ))}
      </Links>
    </Flex>
  </Wrapper>
);
